import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const header = document.querySelector("header");

window.addEventListener ("scroll", function() {
    header.classList.toggle ("sticky", this.window.scrollY > 0);
})

let menu = document.querySelector('#menu-icon');
let navbar = document.querySelector('.navbar');

menu.onclick = () => {
    menu.classList.toggle('bx-x');
    navbar.classList.toggle('active');
};

window.onscroll = () => {
    menu.classList.remove('bx-x');
    navbar.classList.remove('active');
}

const form = document.querySelector('form')
const count = form.children.length
for(let i = 0; i < count; i++){
    form.children[i].addEventListener('click', () => {
        alert("Al momento questa sezione è in manutenzione. In alternativa è possibile scrivere all'indirizzo contact@davidebarca.it o tramite i contatti a disposizione!")
    })
}

// Animations
let tlHome = new gsap.timeline({scrollTrigger: {trigger:".home", start:"50% bottom"}})
tlHome.from(".home-text h4", {opacity:0, duration:0.5, delay:0.5, y: 20})
tlHome.from(".home-text h1", {opacity:0, duration:0.5, delay:0, y: 20}, "-=0.3")
tlHome.from(".home-text h3", {opacity:0, duration:0.5, delay:0, y: 20}, "-=0.3")
tlHome.to(".home-text a", {opacity:1, duration:0.5, delay:0, y: 0}, "-=0.3")
tlHome.from(".navbar li", {opacity: 0, duration:0.5, stagger:0.1, x: -20}, "-=1")

let tlAbout = new gsap.timeline({scrollTrigger: {trigger:".about", start:"20% bottom"}})
tlAbout.from(".about-img", {opacity: 0, duration: 0.5, delay: 0.5, x: -40})
tlAbout.from(".about-text h2", {opacity: 0, duration: 0.5, delay: 0.5, x: 40}, "-=0.5")
tlAbout.from(".about-text h5", {opacity: 0, duration: 0.5, delay: 0.5, x: 40}, "-=0.8")
tlAbout.from(".about-text p", {opacity: 0, duration: 0.5, delay: 0.5, x: 40}, "-=0.8")
tlAbout.to(".about-text a", {opacity: 1, duration: 0.5, delay: 0.5, x: 40}, "-=0.8")

let tlSkills = new gsap.timeline({scrollTrigger: {trigger:".services", start:"40% bottom"}})
tlSkills.from(".services div h2", {opacity: 0, duration: 0.5, delay: 0, y: 20})
tlSkills.from(".services .center p", {opacity: 0, duration: 0.5, delay: 0, y: 20}, "-=0.3")
tlSkills.to(".service-content .row", {opacity: 1, duration: 0.5, delay: 0, y: 0, stagger:0.2}, "-=0.3")

let tlPassioni = new gsap.timeline({scrollTrigger: {trigger:".portfolio", start:"40% bottom"}})
tlPassioni.from(".portfolio div h2", {opacity: 0, duration: 0.5, delay: 0, y: 20})
tlPassioni.to(".portfolio-content .box", {opacity: 1, duration: 0.5, delay: 0, y: 0, stagger:0.2})